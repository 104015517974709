import type { IncomingMessage, OutgoingMessage } from 'http';
import type { AuthClient } from '@dx-ui/framework-auth-provider';
import { mergeCacheControl } from '@dx-ui/framework-merge-cache-control';
import type {
  MutationFunction,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
} from '@tanstack/react-query';
import type { OneLinkConfig } from '@dx-ui/framework-i18n';
import { shouldIncludeLanguageParam } from '@dx-ui/framework-i18n';
import {
  causedByForbidden,
  causedByUnauthorized,
  generateReferrer,
  getSafeLanguage,
  getVisitorId,
} from './default-fetch-fn-utils';
import { v4 } from 'uuid';
import type { DXExecutionResult, QueryDebugInfo, QueryKeyArgs, QueryRequestArgs } from './types';
import { GraphError, ServerAuthError } from './types';
import { extractFiles, isExtractableFile } from './extract-files';
import FormData from 'form-data';
import { logWarning } from '@dx-ui/framework-logger';
import { isBrowser } from '@dx-ui/utilities-is-browser';

type QueryRequest = QueryRequestArgs & MakeDefaultFnArgs;

type QueryResult = DXExecutionResult['data'];

const forwardHeaders = ['dx-preview', 'dx-toggles'];

export async function makeRequest(
  queryRequest: QueryRequest,
  signal?: AbortSignal,
  retries = 0
): Promise<QueryResult> {
  const {
    appName,
    authClient,
    authenticatedOperationNames,
    handleAuthenticatedOperationError,
    handleRequestEndpoint,
    handleRequestHeaders,
    customHeaders,
    document,
    oneLinkConfig,
    referrer: referrerArg,
    referrerPolicy,
    routerLocale = '',
    serverRequest: serverRequestArg,
    serverResponse,
    ssrMode = false,
    variables,
    variablesToIncludeInParams,
  } = queryRequest;

  if (!authClient) {
    throw new Error('auth client not provided');
  }
  const serverRequest = serverRequestArg || serverResponse?.req;
  const { operationString: query, operationName, originalOpName } = document;
  const requestBody = { query, operationName, variables };
  const authenticatedOperation = authenticatedOperationNames?.includes(operationName);
  const endpoint = handleRequestEndpoint?.(serverRequest) || authClient.getGqlEndpoint();
  let authHeader: string | null = null;

  if (authenticatedOperation && !authClient.getIsLoggedIn()) {
    const accessToken = await handleAuthenticatedOperationError?.().catch(() => null);
    authHeader = accessToken ? `Bearer ${accessToken}` : await authClient.getAuthorizationHeader();
  } else {
    authHeader = await authClient.getAuthorizationHeader();
  }

  const visitoridHeader = serverResponse?.req?.headers['visitorid'] as string | undefined;

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: authHeader,
    'dx-platform': (serverResponse?.req?.headers['dx-platform'] as string) ?? 'web',
    ...(visitoridHeader && { visitorid: visitoridHeader }),
    ...customHeaders,
    ...handleRequestHeaders?.(serverRequest),
  };

  const bodyLanguage =
    (variables?.language as string | undefined) || (variables?.lang as string | undefined) || 'en';
  const browserUrl = isBrowser ? window.location.href : '';
  const includeLanguageParam = shouldIncludeLanguageParam(
    routerLocale,
    appName || '',
    browserUrl,
    oneLinkConfig
  );

  if (ssrMode) {
    headers['dx-trusted-app'] = 'true';
  }

  // Always forward these headers from the incoming request
  forwardHeaders.forEach((header) => {
    if (serverResponse?.req?.headers[header]) {
      headers[header] = serverResponse.req.headers[header] as string;
    }
  });

  // Add generated visitorId & messsageId
  const visitorId = getVisitorId();
  if (visitorId) {
    const requestId = v4().replace(/-/g, '');
    const hltClientMessageId = `${visitorId}-${requestId}`;
    //If custom header for visitorid is set, use that instead of the generated one
    headers['visitorid'] = visitoridHeader || visitorId;
    headers['hltClientMessageId'] = hltClientMessageId;
  }

  // Add referrer
  const referrer = referrerArg || generateReferrer(endpoint, routerLocale, serverRequest);

  // Create params from variables
  const paramsFromVariables: Record<string, unknown> = {};
  if (variablesToIncludeInParams?.length) {
    variablesToIncludeInParams.forEach((variable) => {
      const value = variables?.[variable];
      if (value) {
        paramsFromVariables[variable] = value;
      }
    });
  }

  // Add params
  const params = new URLSearchParams({
    appName,
    operationName,
    originalOpName,
    bl: bodyLanguage,
    ...paramsFromVariables,
    ...(includeLanguageParam && { language: getSafeLanguage(routerLocale) }),
  }).toString();

  // Append files
  // https://github.com/jaydenseric/apollo-upload-client/blob/master/public/createUploadLink.js
  const { clone, files } = extractFiles(requestBody, '', isExtractableFile);
  const form = new FormData();
  if (files.size) {
    // Automatically set by `fetch` when the `body` is a `FormData` instance.
    delete headers['Content-Type'];

    // GraphQL multipart request spec:
    // https://github.com/jaydenseric/graphql-multipart-request-spec
    form.append('operations', JSON.stringify(clone));

    const map: Record<number, string[]> = {};
    let i = 0;
    files.forEach((paths) => {
      map[++i] = paths;
    });
    form.append('map', JSON.stringify(map));

    i = 0;
    files.forEach((_, file: File) => {
      formDataAppendFile(form, ++i, file);
    });
  }

  const finalUrl = `${endpoint}?${params}`;

  const response = await fetch(finalUrl, {
    method: 'POST',
    headers,
    referrer,
    referrerPolicy,
    body: files.size ? (form as unknown as BodyInit) : JSON.stringify(requestBody),
    signal,
  });

  const refreshAndRetry = async () => {
    await authClient.refreshToken();
    return makeRequest(queryRequest, signal, retries + 1);
  };

  if (!response.ok) {
    const responseText = await response.text();
    let jsonResult: DXExecutionResult | undefined;
    try {
      jsonResult = JSON.parse(responseText);
    } catch (e) {
      // ignore failed to parse json
    }

    const isForbidden = response.status === 403;

    const referenceError = jsonResult?.errors?.map((er) => er['reference-error']);
    // log ref to dynatrace
    referenceError?.forEach((ref) => {
      if (ref) {
        logWarning('403', '403 reference', ref);
      }
    });

    if (authenticatedOperation) {
      if (!isForbidden && authClient.getIsLoggedIn() && retries < 1) {
        return refreshAndRetry();
      }
      const errorMessage = jsonResult?.errors?.[0]?.message || '';
      throw new ServerAuthError(response.status, errorMessage);
    }

    const isUnauthorized = response.status === 401;
    if (isUnauthorized && retries < 1) {
      return refreshAndRetry();
    }
    // handle errors if response is not ok
    const shouldRetry =
      jsonResult && handleResultErrors(jsonResult, authClient, authenticatedOperation, retries);
    if (shouldRetry) return refreshAndRetry();

    // request failed for unknown reason
    throw new Error(`request failed ${response.status} ${responseText}`);
  }

  // merge cache headers if server
  mergeCacheControl(response, serverResponse);

  const result: DXExecutionResult = await response.json();

  // handle errors if response is ok
  const shouldRetry = handleResultErrors(result, authClient, authenticatedOperation, retries);
  if (shouldRetry) return refreshAndRetry();

  if (response.headers.get('dx-completeness') === '0') {
    triggerInCompleteEvent({
      dxCompleteness: '0',
      requestBody: {
        operationName,
        originalOpName,
      },
    });
  }

  return {
    ...result.data,
    __info__: {
      dxCompleteness: response.headers.get('dx-completeness'),
      isSSR: typeof window === 'undefined',
      operationName,
      originalOpName,
    },
  };
}

export type IncompleteQueryEvent = QueryDebugInfo & {
  message: string;
};
function triggerInCompleteEvent({
  requestBody,
  dxCompleteness,
}: {
  requestBody: Partial<DXExecutionResult['__info__']>;
  dxCompleteness: '0' | '1';
}) {
  if (isBrowser) {
    const event = new CustomEvent<IncompleteQueryEvent>('dx-incomplete', {
      detail: {
        message: 'Incomplete data received from server',
        operationName: requestBody?.operationName || '',
        originalOpName: requestBody?.originalOpName || '',
        dxCompleteness,
        isSSR: false,
      },
    });
    window.dispatchEvent(event);
  }
}

export type MakeDefaultFnOneLinkArgs =
  | {
      oneLinkConfig?: never;
      routerLocale?: never;
    }
  | {
      oneLinkConfig: OneLinkConfig | null;
      routerLocale: string;
    };

export type MakeDefaultFnAuthenticatedOperationErrorArgs =
  | {
      handleAuthenticatedOperationError: () => Promise<string | null>;
      authenticatedOperationNames: string[];
    }
  | {
      handleAuthenticatedOperationError?: never;
      authenticatedOperationNames?: never;
    };

export type MakeDefaultFnOptionalArgs = MakeDefaultFnOneLinkArgs &
  MakeDefaultFnAuthenticatedOperationErrorArgs;

export type MakeDefaultFnArgs = {
  appName: string;
  authClient: AuthClient;
  customHeaders?: Record<string, string>;
  customParams?: Record<string, string>;
  handleRequestEndpoint?: (req?: IncomingMessage) => string;
  handleRequestHeaders?: (req?: IncomingMessage) => Record<string, string> | undefined;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  serverRequest?: IncomingMessage;
  serverResponse?: OutgoingMessage;
  ssrMode?: boolean;
  variablesToIncludeInParams?: string[];
} & MakeDefaultFnOptionalArgs;

export function makeDefaultQueryFn(args: MakeDefaultFnArgs): QueryFunction<QueryResult, QueryKey> {
  return ({ queryKey, signal }: QueryFunctionContext<QueryKey>): Promise<QueryResult> => {
    const [query, variables] = queryKey as QueryKeyArgs;
    return makeRequest(
      {
        ...args,
        document: query,
        variables,
      },
      signal
    );
  };
}

export function makeDefaultMutationFn(args: MakeDefaultFnArgs): MutationFunction {
  return (params): Promise<unknown> => {
    const [mutation, variables] = params as QueryKeyArgs;
    return makeRequest({
      ...args,
      document: mutation,
      variables,
    });
  };
}

function formDataAppendFile(formData: FormData, fieldName: number, file: File) {
  formData.append(fieldName.toString(), file, file.name);
}

function handleResultErrors(
  result: DXExecutionResult,
  authClient: AuthClient,
  authenticatedOperation = false,
  retries: number
) {
  if (result.errors) {
    const error = new GraphError(result);

    if (authenticatedOperation) {
      if (
        (causedByUnauthorized(error) || causedByForbidden(error)) &&
        authClient.getIsLoggedIn() &&
        retries < 1
      ) {
        // return true to retry
        return true;
      }
    }
    throw error;
  }
  return false;
}
